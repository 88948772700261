.footer-container{
  height: 80px;
  background: rgb(11,17,79);
  background: linear-gradient(23deg, rgba(11,17,79,1) 0%, rgba(9,72,121,1) 50%);
  color: #fff;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.footer-copyright{
  position: absolute;
  width: 30vw;
  display: inline;
  justify-self: start;
  left: 10px;
}

.footer-author{
  display: inline;
  position: absolute;
  right: 10px;
}