.offer-card-container{
  height: 200px;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px 2px hsla(216, 98%, 50%, 0.729);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 45% 1fr;
}
.photo-container{
  height: 200px;
  
}
.offer-photo{
  height: 200px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.offer-card-text-container{
    max-width: 60vw;
    
}
.offer-card-text-container > h1{
  font-size: 1.5vw;
  text-align: center;
  color:rgb(2, 109, 197);
  padding-top: 0.5rem;
}
.offer-card-text-container > p{
  color: #242424;
  font-size: 1vw;
  padding: 0.5rem;
  line-height: 20px;
}

@media screen and (max-width: 960px){
  .offer-card-container{
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
  }
  .photo-container{
    display: none;
  }
  .offer-card-text-container > h1{
    font-size: 16px;
    padding: 10px;
  }
  .offer-card-text-container > p{
    display: none;
  }
}