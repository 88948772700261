.offer-container{
  display: grid;
  grid-template-columns: repeat(2, 30vw);
  gap: 20px;
  column-gap: 5rem;
  margin-bottom: 30px;
  margin-top: 20px;
  justify-content: center;
}

.offer-contact-box{
  display: none;
}
@media screen and (max-width: 960px){
  .offer-container{
    grid-template-columns: repeat(2,40vw);
    column-gap: 2rem;
  }
  .offer-contact-box{
    display: block;
    width: fit-content;
    margin-inline: auto;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 2px #0468fdba;
    border-radius: 5px;
    height: fit-content;
  }
  .offer-contact-box > h1{
    padding-right: 3rem;
    padding-top: 10px;
    padding-left: 1.5rem;
    font-size: 36px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color:rgb(2, 109, 197);
  }
  
  .offer-contact-box > p{
    color: rgb(78, 78, 78);
    margin-left: 1.5rem;
    padding-top: 1rem;
  }
    p > a{
    color: rgb(78, 78, 78);
  }
  

}