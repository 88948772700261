.card-container{
  box-shadow: 0 2px 5px dodgerblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 200ms ease-in;
  background-color: aliceblue;
  margin-right: 5px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.card-link{
  text-decoration: none;
  color: black;
  text-align: center;
  height: 100%;
}
.card-photo{
  width: 100%;
  height: 7rem;
  height: 7rem;
  object-fit: scale-down;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-description{
  position: relative;
}
.card-description > *{
  font-size: 11px;
  z-index: 10;
}

.card-description > p{
  color: grey;
  padding-bottom: 5px;
}

.card-button{
  padding: 0.5rem;
  border: 2px solid green;;
  color: #fff;
  background: green;
  border-radius: 5px;
  font-size: 0.75rem;
  cursor: pointer;
  bottom: 0.3rem;
  margin: 1rem;
}
.znany-lekarz-small-logo{
  position: absolute;
  bottom: 0;
  right: 2px;
  width: 15px;
  height: 15px;
  border-bottom-right-radius: 5px;
}

.card-container:hover{
  transform: scale(1.07);
}
.card-container:hover .card-button{
  color: green;
  background-color: #fff;
}



@media  screen and (max-width: 800px) {
  .card-photo{
    display: none;
  }
  .card-container{
    height: 50px;
    box-shadow: 0;
  }
  .card-description{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    align-items: center;
  }
  .card-description > h1{
    margin-top: 5px;
  }
  .card-description > p{
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 380px) {
  .card-container{
    height: 60px;
  }
}