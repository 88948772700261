.hero-container{
  position: relative;
  height: 44vh;
}

.hero-photo{
  display: block;
  width: 100%;
  text-align: center;
  height: 100%;
  background: linear-gradient(to top, #0468fdba, #0778facc), url(C:\folder\projects\prokardio\public\offerPictures\poradniakardiologiczna.jpg) no-repeat; 
  background-size:contain;
  background-size: 100% 100%;
}
.hero-typewriter > h1{
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 4vw;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap; 
  margin: 0 auto;
  letter-spacing: .15em;
}

.hero-photo > p{
  position: absolute;
  line-height: 35px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2vw;
}
.hero-photo > p > a{
  color: #fff;
}

.nasi-lekarze{
  display: block;
  position: absolute;
  bottom: 5px;
  left: 20px;
  color: #fff;
  font-size: 2.5vw;
}

.hero-container > h3{
  display: none;
}
@media screen and (max-width: 1000px) {
  .hero-container{
    height: 30vh;
  }
  .hero-typewriter > h1{
    top: 20%;
  }
  .hero-photo > p{
    line-height: normal;
  }
}
@media  screen and (max-width: 800px) {
  .hero-container{
    height: fit-content;
    margin: 10px;
    box-shadow: 0 2px 10px dodgerblue;
  }
  .hero-photo{
    display: none;
  }
  .hero-photo > *{
    display: none;
  }
  .hero-container{
    text-align: center;
    border-radius: 5px;
    vertical-align: middle;
  }
  .hero-container > h3{
    padding: 13px;
    display: block;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color:rgb(2, 109, 197);

  }
}

@media screen and (max-width: 600px) {
  .hero-container > h3{
    font-size: 12px;
  }
}
@media screen and (max-width: 380px) {
  .hero-container > h3{
    font-size: 10px;
  }
}