.navbar{
  background: rgb(11,17,79);
  background: linear-gradient(23deg, rgba(11,17,79,1) 0%, rgba(9,72,121,1) 50%);
  height: 80px;
  display: flex;
  font-size: 1.2rem;
  position: sticky;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 999;
}

.navbar-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 130px;
  height: 75px;
  text-decoration: none;
  border-radius: 1px;
}
.navbar-logo-text{
  background-color: #fff;
  color: rgb(2, 71, 139);
  font-size: 10.5px;
}
.navbar-logo-img{
  width: 120px;
  height: 60px;
}

.navbar-menu{
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-left: 50px;
  margin-top: 15px;
  
}

.nav-link{
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 0.5px solid grey;
}

.nav-link:hover{
  border-bottom: 4px solid deepskyblue;
  transition: all 0.2s ease-in-out;
  color: deepskyblue;
}

.znanylekarz{
  align-items: center;
  justify-self: start;
  display: flex;
  margin-left: 50px;
}
.znanylekarz-img{
  width: 120px;
  height: 60px;
  border-radius: 5px;
}

.znanylekarz-img:hover{
  border: 2px solid #fff;
}

.znanylekarz-mobile{
  display: none;
}
.menu-icon{
  display: none;
}

@media  screen and (max-width: 960px) {
  .NavbarItems{
    position: relative;
  }

  .navbar-menu{
    display: grid;
    grid-template-columns: 100vw;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 65px;
    left: -100%;
    opacity: 1;
    margin-left: 0;
  }
  .navbar-logo{
    position: absolute;
    left: 100px;
  }
  .navbar-menu.active{
    background: rgb(11,17,79);
    background: linear-gradient(159deg, rgba(11,17,79,1) 0%, rgba(9,72,121,1) 20%);
    top: 65px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .menu-icon{
    display: block;
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff
  }
  .fa-times{
    color: #fff;
    font-size: 2rem;
  }
  .nav-link{
    margin-top: 0;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    border: 0;
  }
  .nav-link:hover{
    background-color: deepskyblue;
    color: #242424;
    border: 0;
  }
  .navbar-item{
    border-bottom: 0.5px solid grey;
  }
}
  