.contact-container{
  display: flex;
}

.contact-box{
  width: fit-content;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px 2px #0468fdba;
  border-radius: 5px;
  height: fit-content;
}
.contact-box > h1{
  padding-right: 3rem;
  padding-top: 10px;
  padding-left: 1.5rem;
  font-size: 36px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color:rgb(2, 109, 197);
}

.contact-box > p{
  color: rgb(78, 78, 78);
  margin-left: 1.5rem;
  padding-top: 1rem;
}
  p > a{
  color: rgb(78, 78, 78);
}

.map-button{
  margin-left: 1.5rem;
  width: fit-content;
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  cursor: pointer;
}
.map-button > a > p{
  color: rgb(78, 78, 78);
  padding: 0.5rem;
  border: 2px solid dodgerblue;
  border-radius: 5px;
}
.map-button > a{
  text-decoration: none;
}
.map-button > a >p:hover{
  background-color: dodgerblue;
  color: #fff;
}
.map-container{
  margin-left: 1.5rem;
  height: 50vh;
  width: 30vw;
  border: 0;

}

.right{
  position: relative;
}
.right > img{
  position: absolute;
  height: 50vh;
  width: 40vw;
  top: 20%;
  left: 10vw;
}

@media screen and (max-width: 960px) {
  .contact-container{
    display: block;
    
  }

  .right{
    margin-left: -5vw;
  }
  .right > img{
    position: relative;
    width: 90vw;
  }
  .contact-box{
    margin-inline: auto;
  }
  .left > iframe{
    display: none;
  }
  
}