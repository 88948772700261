.registration-container{
  background-color: rgb(247, 245, 245);
  height: fit-content;
  margin-top: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.registration-container > h1{
  padding-top: 20px;
  margin-left: 30px;
  font-size: 48px;
  
  color:rgb(2, 109, 197);
}

.registration-container > p{
  color: #242424;
  padding-top: 20px;
  margin-left: 30px;
  font-size: 18px;
}
.registration-container > p > a{
  color: #242424;
}

.registration-button{
  border: 2px solid black;
  height: 20%;
  width: 15%;
  text-align: center;
  margin-left: 30px;
  margin-top: 30px;
  padding: 0.5rem;
  border: 2px solid dodgerblue;
  background: #fff;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: black;
  letter-spacing: 1px;
}

.registration-link{
  text-decoration: none;
}

.registration-link:hover .registration-button{
  color: #fff;
  background-color: dodgerblue;
  transition: 0.3s ease-in-out;
}

.fa-calendar-days{
  position: absolute;
  right: 10%;
  font-size: 8rem;
  color: grey;
  margin-top: 3rem;
}

@media  screen and (max-width: 960px) {
  .fa-calendar-days{
    display: none;
  }

  .registration-button{
    width: 50%;
  }
  .registration-container > h1{
    font-size: 30px;
  }
  .registration-container > p{
    font-size: 16px;
  }
}