.doctorsbar-container{
  margin-top: 10px;
  background-color: #fff;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, 11rem);
  gap: 1rem;
  justify-content: center;
  
}

@media screen and (max-width: 800px) {
  .cards{
    border: 2px solid dodgerblue;
    border-radius: 5px;
  }
  
}
@media screen and (max-width: 380px) {
  .doctorsbar-container{
  grid-template-columns: repeat(auto-fit, 10rem);
  }
  
}
